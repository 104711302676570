<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card icon="mdi-account-group">
          <!-- <debug-btn :item="responseData"></debug-btn> -->
          <template v-slot:heading>
            <h3>{{title}}</h3>
          </template>
          <v-row class="mt-3  ">
            <v-col align="left">
              <div>
                <!-- <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" v-bind:color="rowData.length > 0 ? 'success' : 'info darken-3'" @click="dialogLampiranSKP = true" dark class="mx-1">
                      <v-icon small left>mdi-attachment</v-icon>Isi Lampiran SKP
                    </v-btn>
                  </template>
                  <span> Isi Lampiran SKP</span>
                </v-tooltip> -->
              </div>
            </v-col>
          </v-row>
          <v-row v-if="loadingBtn.dataPegawai" class="mt-5">
            <v-col v-for="i in [1,2,3,4,5,6]" :key="i">
              <v-skeleton-loader
              class="mx-auto"
              min-width="300"
              type="card"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row v-if="pegawai.length > 0" class="mt-10">
            <v-col v-for="(peg,i) in pegawai" :key="i" align="center">
              <base-material-card
                class="v-card-profile"
                :avatar="peg.foto"
                style="width:270px; "
              >
                <v-card-text class="text-center" height='fit-content'>
                  <h4 class="display-2 mb-1 orange--text text--darken-2" style="height:55px;">
                    {{peg.nama}}
                  </h4>
                  <h4 class="font-weight-light grey--text mb-2">
                    NIP. {{peg.nip}}
                  </h4>
                  <h4 class="font-weight-light green--text" style="font-size:12px; height:30px">
                    {{peg.jabatan}}
                  </h4>
                  <div class="mt-5">
                    <!-- <v-chip small label :class="reviewStatus(peg)" class="ma-1" dark>{{peg.status}}</v-chip>
                    <v-chip small label class="ma-1 orange darken-2" dark>{{peg.jumlah}}/{{peg.harus}} Pertanyaan</v-chip> -->
                  </div>
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                  <div>
                  <v-btn
                  color="cyan darken-4"
                  dark
                  rounded
                  class="mr-0"
                  :loading="loadingEl == i"
                  @click="openDialogLihatLampiranSKP(peg, i, false)"
                  :small="true"
                  >
                  <span> Lihat Lampiran SKP </span>
                  </v-btn>
                  </div>
                </v-card-actions>
              </base-material-card>
            </v-col>
          </v-row>
          <v-row v-else justify="center">
            <v-col cols="5" v-if="loadingBtn.dataPegawai == false">
              <v-alert
              color="blue-grey"
              dark
              dense
              icon="mdi-account-group"
              prominent
            >
              Tidak ada pegawai
            </v-alert>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogLampiranSKP" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Lampiran SKP Permenpan 6 2022</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col style="background-color: white;margin: 0px 0px 12px 12px;">
              <div class="ma-3 d-flex justify-center align-center flex-column">
                <base-material-card
                  class="v-card-profile"
                  :avatar="dataPegawaiBawahan.foto"
                  style="width:270px; ">
                  <v-card-text class="text-center" height='fit-content'>
                    <h3 class="mt-1 orange--text text--darken-2 headline">{{dataPegawaiBawahan.nama}}</h3>
                    <div class="mt-1 grey--text">NIP. {{dataPegawaiBawahan.nip}}</div>
                    <div class="mt-1 green--text">{{dataPegawaiBawahan.jabatan}}</div>
                  </v-card-text>
                </base-material-card>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-simple-table>
          <thead>
            <tr>
              <th>Dukungan Sumber Daya</th>
              <th>Skema Pertanggungjawaban</th>
              <th>Konsekuensi</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="rowData.length > 0">
              <tr v-for="(item, key) in rowData" v-bind:key="key.no">
                <td>{{item.dukungan_sumber_daya}}</td>
                <td>{{item.skema_pertanggungjawaban}}</td>
                <td>{{item.konsekuensi}}</td>
                <!-- <td>
                  <v-btn color="error" @click="removeItem(item)">Hapus Baris</v-btn>
                </td> -->
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="3" class="text-center">
                  <p class="grey--text">Data kosong</p>
                </td>
              </tr>
            </template>
          </tbody>
        </v-simple-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="tutupFormLampiranSKP()" color="warning">tutup</v-btn>
          <!-- <v-btn @click="simpanLampiranSKP()" :loading="loadingBtn.lampiran" color="primary">Simpan</v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import _ from 'lodash'
import store from '@/store'
import SKPService from '@/services/SKPService'
// import DebugBtn from '@/components/common/DebugBtn'
// import ConfirmBtn from '@/components/base/ConfirmBtn'
import {defaultRules} from '@/utils/lib'
import _g from '../../global'
// import FormLampiranSKP from './FormLampiranSKP.vue'
import {local} from '@/store/local'
import axios from 'axios'
export default {
  components:{
    // ConfirmBtn,
    // DebugBtn,
    // FormLampiranSKP
  },

  data(){
    return{
      valid:true,
      title:'Lampiran SKP',
      forceUpdate:false,
      formDetail:{},
      formValidasi:{},
      formTolakKeterangan:"",
      idTolak:null,
      loading:false,
      detailData:{},
      triwulan:1,
      hides:[],
      dialogDetail:false,
      dialogValidasi:false,
      dialogTolak:false,
      selectedItem:{},
      tabItems:['Triwulan I', 'Triwulan II', 'Triwulan III', 'Triwulan IV', 'Tahunan'],
      tableValues:{},
      loadingBtn:{
        terima:false,
        tolak:false,
        validasi:false,
        dataPegawai:false
      },
      ratingList:['kurang', 'kurang berhasil', 'cukup', 'berhasil', 'sangat berhasil'],
      rules:{
        ...defaultRules,
        min30: v=> (v && v.length > 30) || 'Minimal 30 karakter'
      },
      dialogFormEkspektasiPerilaku: false,
      dataPegawaiBawahan: {},
      dataEkspektasiBawahan:{},
      // dataRespon:{},
      isUmpanBalik: false,
      loadingEl:-1,
      loadingElUmpanBalik:-1,
      dataPegawaiBaru: [],
      listPegawaiSKPD: [],
      modelListPegawaiSKPD: [],
      avatarImages:{},
      kode_skpd: null,
      walikota: false,
      tahun: null,
      dialogLampiranSKP: false,
      rowData: [],
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.triwulanFromRoute = to.query.jenis
    })
  },

  watch:{
    triwulan:function(val){
      console.log(val)
      if(this.triwulanFromRoute != null){
        this.triwulan = this.triwulanFromRoute
      }
      this.updateTable()
    },
    dialogFormEkspektasiPerilaku:function(val){
      if(val){
        this.$nextTick(() => {
          this.$refs.refFormEkspektasiPerilaku.scrollTop = this.$refs.refFormEkspektasiPerilaku.scrollHeight
        })
      }
    }
  },

  created(){
    this.year = new Date().getFullYear()-1
    this.triwulan = _g.getTriwulan().toString()
    this.updateTable()

    this.tahun = this.$store.getters["user/data"].tahun
    let username = this.$store.getters["user/data"].username
    if(username == "walikotabdg"){
      this.walikota = true
      // this.menuTitle = 'Sasaran Strategis Tahunan'
    }else{
      this.walikota = false
      // this.menuTitle = 'SKP Tahunan'
    }

    this.rowData = []; this.no = 0;
    if(this.tahun >= 2022){
      this.updateLampiranSKP()
    }
  },

  computed:{
    triwulan_text(){
      return isNaN(this.triwulan ) ? 'Tahunan' : 'Triwulan ' + this.triwulan
    },
  },

  mounted(){
    this.getDataPegawaiSKPD()
  },

  methods:{

    updateTable(){
      this.pegawai=[]
      this.loadingBtn.dataPegawai = true
      // SKPService.getDataValidasiSKPTriwulan(this.triwulan).then(response =>{
      //   // this.responseData = response.data
      //   this.pegawai=response.data.data
      //   this.loadingBtn.dataPegawai = false
      // })
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }

      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_get_data = base_url_api + 'v1/validasi/get-data'
      axios.post(url_get_data, {
        nip: current.username,
        bulan: this.triwulan,
      }).then(response => {
        this.pegawai=response.data.data
        this.loadingBtn.dataPegawai = false
      }).finally(()=>{
      }).catch(err => {
        var error = err.message ? err.message : err.response.data.message
        this.loadingEl = -1
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    closeDialog(){
      this.formValidasi = {}
      this.$refs.form.resetValidation()
      this.dialogValidasi=false
    },

    openDialogLihatLampiranSKP(peg, idx, isUmpanBalik){
      var current = store.state.user.data
      var nip = peg.peg_nip ? peg.peg_nip : peg.nip
      this.dataPegawaiBawahan = peg
      SKPService.getLampiranSKP(nip).then(response=>{
        let res =response.data
        let dsd = res.data[0] != undefined ? JSON.parse(res.data[0].dukungan_sumber_daya) : []
        let sp = res.data[0] != undefined ? JSON.parse(res.data[0].skema_pertanggungjawaban) : []
        let k = res.data[0] != undefined ? JSON.parse(res.data[0].konsekuensi) : []
        let size = [dsd.length, sp.length, k.length]
        let max_size = Math.max(...size)
        this.rowData = []
        for (let i = 0; i < max_size; i++) {
          this.rowData.push({
            no: i,
            dukungan_sumber_daya : dsd[i] != undefined ? dsd[i] : '',
            skema_pertanggungjawaban : sp[i] != undefined ? sp[i] : '',
            konsekuensi : k[i] != undefined ? k[i] : ''
          })
        }
        this.dialogLampiranSKP = true
      })
    },

    getImg(nip, peg_foto){
      let avatarImgUrl = "https://simpeg.bandung.go.id/uploads/" + peg_foto
      this.avatarImages[nip] = avatarImgUrl
      return avatarImgUrl
    },

    getDataPegawaiSKPD(kolok){
      // let kode_skpd = local.getLocal("kolok") ? local.getLocal("kolok") : this.$store.getters["user/data"].data_asn.kolok
      let kode_skpd = kolok ? kolok : local.getLocal("kolok")
      // SKPService.getPegawaiSKPD(kode_skpd).then(response=>{
      //   response.data.data.forEach(element => {
      //     element.foto = this.getImg(element.nip, element.peg_foto)
      //   });
      //   this.listPegawaiSKPD = response.data.data
      // })
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }

      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_get_data = base_url_api + 'v1/get-pegawai-skpd-req-post'
      axios.post(url_get_data, {
        nip: current.username, kode_skpd: kode_skpd
      }).then(response => {
        response.data.data.forEach(element => {
          element.foto = this.getImg(element.nip, element.peg_foto)
        });
        this.listPegawaiSKPD = response.data.data
      }).catch(err => {
        var error = err.message ? err.message : err.response.data.message
        this.loadingEl = -1
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    getNipNama(item) {
      return `${item.peg_nip} - ${item.peg_nama}`;
      // return `${item.nip} - ${item.nama}`;
    },

    addRow(){
      this.no = this.dataPegawaiBaru.length
      this.dataPegawaiBaru = []
      this.modelListPegawaiSKPD.forEach(element => {
        this.no += 1
        var my_object = {
          foto: element.foto,
          nama: element.peg_nama,
          nip: element.peg_nip,
          // peg_nama: element.nama,
          // peg_nip: element.nip,
          jabatan: element.jabatan,
          no:this.no,
          checked: false,
          disabled_assignment: false
        };
        this.dataPegawaiBaru.push(my_object)
      });
    },

    removeItem(item) {
      this.no -= 1
      for (let i = 0; i < this.dataPegawaiBaru.length; i++) {
        const el = this.dataPegawaiBaru[i];
        if(el.no == item.no){
          this.dataPegawaiBaru.splice(i, 1)
        }
      }
      // re-indexing
      for (let i = 0; i < this.dataPegawaiBaru.length; i++) {
        this.dataPegawaiBaru[i].no = i+1
      }
      console.log(this.dataPegawaiBaru)
    },

    loadListPegawaiSKPD(){
      this.getDataPegawaiSKPD(this.kode_skpd)
    },

    simpanLampiranSKP(){
      if(this.rowData.length == 0){
        store.commit('snackbar/setSnack',{
            message:'Maaf, Lengkapi dahulu data lampiran SKP anda.',
            color:'warning'})

        return
      }
      this.loadingBtn.lampiran = true
      var dsd = []; var sp = []; var k = []
      this.rowData.forEach(el => {
        dsd.push(el.dukungan_sumber_daya)
        sp.push(el.skema_pertanggungjawaban)
        k.push(el.konsekuensi)
      });
      var data = {
        dukungan_sumber_daya : JSON.stringify(dsd),
        skema_pertanggungjawaban : JSON.stringify(sp),
        konsekuensi : JSON.stringify(k)
      }
      SKPService.storeLampiranSKP(data).then(response => {
        let res = response.data
        if (res.success){
          store.commit('snackbar/setSnack',{message: res.message, color:'success'})
          this.dialogLampiranSKP = false
        } else {
          store.commit('snackbar/setSnack',{message: res.message, color:'error'})
        }
        this.updateTableTahunan()
      }).finally(()=>{
        this.loadingBtn.lampiran=false
      })
    },

    tutupFormLampiranSKP(){
      this.rowData = []
      this.updateLampiranSKP()
      this.dialogLampiranSKP = false
			this.$emit('onClose',false)
    },

    updateLampiranSKP(){
      SKPService.getLampiranSKP().then(response=>{
        let res =response.data
        let dsd = res.data[0] != undefined ? JSON.parse(res.data[0].dukungan_sumber_daya) : []
        let sp = res.data[0] != undefined ? JSON.parse(res.data[0].skema_pertanggungjawaban) : []
        let k = res.data[0] != undefined ? JSON.parse(res.data[0].konsekuensi) : []
        let size = [dsd.length, sp.length, k.length]
        let max_size = Math.max(...size)
        for (let i = 0; i < max_size; i++) {
          this.rowData.push({
            no: i,
            dukungan_sumber_daya : dsd[i] != undefined ? dsd[i] : '',
            skema_pertanggungjawaban : sp[i] != undefined ? sp[i] : '',
            konsekuensi : k[i] != undefined ? k[i] : ''
          })
        }
      })
    },

  },
}
</script>

<style lang="css" scoped>
</style>
